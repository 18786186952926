import dynamic from "next/dynamic";
import Head from "next/head";
import { useSelector } from "react-redux";
import { TopSpacerBlock } from "~/components/Block";
import { resolveMedia } from "~/external/prismic";
import { ThemeContext, themes } from "~/lib/ThemeContext";

const sliceComponents = {
  title: dynamic(() => import("~/slices/Title")),
  title_and_text: dynamic(() => import("~/slices/TitleAndText")),
  big_copy: dynamic(() => import("~/slices/BigCopy")),
  spacer: dynamic(() => import("~/slices/Spacer")),
  showcase: dynamic(() => import("~/slices/Showcase")),
  product_hero: dynamic(() => import("~/slices/ProductHero")),
  product_features: dynamic(() => import("~/slices/ProductFeatures")),
  product_details: dynamic(() => import("~/slices/ProductDetails")),
  product_about: dynamic(() => import("~/slices/ProductAbout")),
  rich_text: dynamic(() => import("~/slices/RichText")),
  media_full_width: dynamic(() => import("~/slices/MediaFull")),
  bread_crumbs: dynamic(() => import("~/slices/BreadCrumbs")),
  gallery: dynamic(() => import("~/slices/Gallery")),
  card_grid: dynamic(() => import("~/slices/CardGrid")),
  product_grid: dynamic(() => import("~/slices/ProductGrid")),
  card_slider: dynamic(() => import("~/slices/CardSlider")),
  sub_navigation: dynamic(() => import("~/slices/SubNav")),
  sub_navigation_fixed: dynamic(() => import("~/slices/SubNavFixed")),
  anchor: dynamic(() => import("~/slices/Anchor")),
  panel: dynamic(() => import("~/slices/Panel")),
  panel_grid: dynamic(() => import("~/slices/PanelGrid")),
  hero_banner_b: dynamic(() => import("~/slices/HeroBannerB")),
  divider: dynamic(() => import("~/slices/Divider")),
  youtube_video: dynamic(() => import("~/slices/YoutubeVideo")),
  call_to_action: dynamic(() => import("~/slices/CallToAction")),
  soundcloud_player: dynamic(() => import("~/slices/SoundCloudPlayer")),
  question_category_grid: dynamic(() => import("~/slices/QuestionCategoryGrid")),
  question_grid: dynamic(() => import("~/slices/QuestionGrid")),
  question_search: dynamic(() => import("~/slices/QuestionSearch")),
  support_contact_form: dynamic(() => import("~/slices/SupportContactForm")),
  resellers_overview: dynamic(() => import("~/slices/ResellersOverview")),
  reseller_selection: dynamic(() => import("~/slices/ResellerSelection")),
  story_category_grid: dynamic(() => import("~/slices/StoryCategoryGrid")),
  stories_overview: dynamic(() => import("~/slices/StoriesOverview")),
  stories_navigation: dynamic(() => import("~/slices/StoriesNavigation")),
  google_forms_embed: dynamic(() => import("~/slices/GoogleFormsEmbed")),
  klaviyo_forms_embed: dynamic(() => import("~/slices/KlaviyoFormsEmbed")),
  country_based_section: dynamic(() => import("~/slices/CountryBasedSection")),
  vertical_video_grid: dynamic(() => import("~/slices/VerticalVideoGrid")),
  iframe: dynamic(() => import("~/slices/IframeEmbed")),
  product_comparison_chart: dynamic(() => import("~/slices/ProductComparisonChart")),
};

function PrismicPage(props) {
  const { page, externalData } = props;

  // Get country data
  const countryData = useSelector((store) => store.countryData);

  // make sure we have a page...
  if (!page) return null;

  // default seo content.
  const { seo = { title: "AIAIAI Audio", index: "index", follow: "follow" } } =
    externalData;

  const currentTheme = page.data.theme
    ? themes[page.data.theme] ?? themes.light
    : themes.light;

  // get the type of spacing in the top of the page
  const pageTopSpacing =
    page.data.spacing_top === "Yes" || !page.data.spacing_top ? "block" : "topBar";

  // get first visible slice, as we need to add a spacer to the top of the page,
  // that has the same color as the first visible slice
  // (this is basically the first slice that is not 'sub_navigation')
  const firstVisibleSlice = page.data.body.find(
    (slice) => slice.slice_type !== "sub_navigation"
  );

  // default colors for spacer
  let firstVisibleSliceColor = currentTheme.foreground;
  let firstVisibleSliceBackgroundColor = currentTheme.background;
  // get color from first slice
  if (firstVisibleSlice) {
    const { text_color, background_color } = firstVisibleSlice.primary;
    if (text_color) firstVisibleSliceColor = text_color;
    if (background_color) firstVisibleSliceBackgroundColor = background_color;
  }

  // Open Graph
  const media = resolveMedia({
    image: page.data.seo_image,
  });

  let imageUrl = null;
  if (media) {
    imageUrl = media[0];
  }

  // we want to parse each entry in data.page
  return (
    <>
      <Head>
        {seo.title && <title>{seo.title}</title>}
        {seo.description && (
          <meta name="description" key="description" content={seo.description} />
        )}
        {seo.keywords && <meta name="keywords" key="keywords" content={seo.keywords} />}
        <meta name="robots" key="robots" content={`${seo.index}, ${seo.follow}`} />
        {/* <!-- Facebook Meta Tags --> */}
        {/* TODO:
            <meta
              property="og:url"
              content=""
            /> */}
        <meta property="og:type" content="website" />
        {seo.title && (
          <meta
            property="og:title"
            content={seo.title} /* TODO: Set different title */
          />
        )}
        {seo.description && (
          <meta
            property="og:description"
            content={seo.description} /* TODO: Set different description */
          />
        )}
        {imageUrl && <meta property="og:image" content={imageUrl} />}
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="aiaiai.audio" />
        {/* TODO:
            <meta
              property="twitter:url"
              content=""
            />
            */}
        {seo.title && (
          <meta
            name="twitter:title"
            content={seo.title} /* TODO: Set different title */
          />
        )}
        {seo.description && (
          <meta
            name="twitter:description"
            content={seo.description} /* TODO: Set different description */
          />
        )}
        {imageUrl && <meta name="twitter:image" content={imageUrl} />}
        <style
          dangerouslySetInnerHTML={{
            __html: `
            body {
              background-color: ${currentTheme.background} !important;
            }
          `,
          }}
        />
      </Head>

      <ThemeContext.Provider value={currentTheme}>
        <TopSpacerBlock
          backgroundColor={firstVisibleSliceBackgroundColor}
          color={firstVisibleSliceColor}
          spacing={pageTopSpacing}
        />

        {page.data.body.map((slice, i) => {
          const { slice_type, primary } = slice;
          const SliceComponent = sliceComponents[slice_type];

          // Check if the slice has country visibility set and if it includes the current country code
          if (
            SliceComponent &&
            (!primary.country_visibility ||
              primary.country_visibility.includes(countryData.code))
          ) {
            return <SliceComponent key={i} slice={slice} externalData={externalData} />;
          }

          // Log the information for the developer
          console.log(
            `Slice '${slice_type}' does not exist or is not visible in this country`
          );

          // Return null to not render anything for this slice
          return null;
        })}
      </ThemeContext.Provider>
    </>
  );
}

export default PrismicPage;
