import getConfig from "next/config";
import Prismic from "prismic-javascript";
import { DefaultClient } from "prismic-javascript/types/client";
import { Document } from "prismic-javascript/types/documents";
import { QueryOptions } from "prismic-javascript/types/ResolvedApi";
import cache from "~/lib/cache";
import {
  DocumentType,
  GenericDocument,
  MappableDocument,
  PageDocument,
  PressKitDocument,
  QuestionCategoryDocument,
  QuestionDocument,
  ResellerDocument,
  StoryCategoryDocument,
  StoryDocument,
} from "./types";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

let sharedClient: DefaultClient = null;

export const PrismicClient = (req = null) => {
  if (typeof document !== "undefined" && sharedClient) return sharedClient;
  const options = {
    ...(req ? { req } : {}),
    ...(publicRuntimeConfig.PRISMIC_ACCESS_TOKEN
      ? { accessToken: publicRuntimeConfig.PRISMIC_ACCESS_TOKEN }
      : {}),
  };
  sharedClient = Prismic.client(publicRuntimeConfig.PRISMIC_ENDPOINT, options);
  return sharedClient;
};

export async function queryDocuments(
  predicates: string[] | string,
  options: QueryOptions = {}, // TODO: Type
  fetchAllPages = false
): Promise<Document[]> {
  // console.log('options =>', options);

  // { fetchLinks : ['author.first-name', 'author.last-name'] }
  // { orderings : '[my.product.price desc]' }
  // { fetch : ['product.title', 'product.price'] }

  const fetchPage = async (page: number) =>
    PrismicClient().query(predicates, { ...options, page });

  // TODO: Add cache!
  let page: number | undefined;
  let numPages = 1;
  let results = [];
  try {
    while (page === undefined || (fetchAllPages && page < numPages)) {
      const nextPage = (page ?? 0) + 1;
      const response = await fetchPage(nextPage);
      page = response.page;
      numPages = response.total_pages;
      results = [...results, ...response.results];
    }

    return results;
  } catch (error) {
    // console.log('ERROR =>', JSON.stringify(error, null, 2));
    throw error;
  }
}

export async function getDocument(
  type: DocumentType,
  uid: string,
  fetchLinks: string[] = []
): Promise<Document> {
  // console.log(fetchLinks);

  /* queryDocument([
    Prismic.Predicates.at('document.type', 'question'),
    Prismic.Predicates.any('my.question.category', [
      'Shipping',
      'Getting started'
    ])
  ]); */

  const key = `${type}|${uid}|links:${fetchLinks.join("+")}`;
  const value = cache.get<Document>(key);
  if (value) return value;

  const document = await PrismicClient().getByUID(type, uid, {
    fetchLinks,
  });

  // console.log('DOCUMENT =>', JSON.stringify(document, null, 2));

  if (!document) {
    return null;
  }

  cache.set(key, document); // , 5 * 60);

  return document;
}

/** LIMITATIONS:
 * - No pagination.
 * - Expected to only work with same document type (unless parsed manually before using mapProperties)
 */
export async function getDocuments(
  type: DocumentType,
  ids: string[],
  fetchLinks: string[] = []
): Promise<Document[]> {
  const key = `${type}|${ids.join("+")}|links:${fetchLinks.join("+")}`;
  const value = cache.get<Document[]>(key);
  if (value) return value;

  const response = await PrismicClient().getByIDs(ids, {
    fetchLinks,
  });

  // console.log('DOCUMENTS =>', JSON.stringify(response.results, null, 2));

  cache.set(
    key,
    response.results,
    // Only set unlimited TTL when having results
    response.results.length == 0 ? 60 : 0
  );

  return response.results;
}

export function mapProperties(
  document: Document,
  type: DocumentType
): MappableDocument {
  if (!document) {
    return null;
  }

  const genericDocument = document as GenericDocument;
  genericDocument.content = {};

  switch (type) {
    case "page":
      const pageDocument = genericDocument as PageDocument;
      pageDocument.content.page_title = genericDocument.data.page_title;

      /* TODO:
      data: {
        page_title: [ [Object] ],
        spacing_top: 'Yes',
        page_type: null,
        body: [ [Object], [Object], [Object] ],
        seo_title: null,
        seo_description: null,
        canonical_url: { link_type: 'Any' },
        seo_image: {},
        seo_keywords: null,
        seo_index_page: 'Yes',
        seo_follow_links: 'Yes'
      }
      */

      return pageDocument;
    case "question":
      const questionDocument = genericDocument as QuestionDocument;
      questionDocument.content.title = genericDocument.data.title;
      questionDocument.content.brief = genericDocument.data.brief || null;
      questionDocument.content.body = genericDocument.data.body || null;
      questionDocument.content.category = genericDocument.data.category || null;
      questionDocument.content.related_products =
        genericDocument.data.related_products?.filter(
          // Filter out empty sections
          (item) => !!item.related_product?.uid
        ) || null;
      questionDocument.content.related_questions =
        genericDocument.data.related_questions?.filter(
          // Since we need there to be a link text (the question) the actual data is required
          (item) => !!item.related_question?.data
        ) || null;
      return questionDocument;
    case "question_category":
      const questionCategoryDocument = genericDocument as QuestionCategoryDocument;
      questionCategoryDocument.content.title = genericDocument.data.title;
      questionCategoryDocument.content.type = genericDocument.data.type;
      questionCategoryDocument.content.description = genericDocument.data.description;
      questionCategoryDocument.content.questions =
        genericDocument.data.questions?.filter(
          // Filter out empty sections
          (item) => !!item.question?.data
        );
      return questionCategoryDocument;
    case "story":
      const storyDocument = genericDocument as StoryDocument;
      storyDocument.content.title = genericDocument.data.title;
      storyDocument.content.short_description =
        genericDocument.data.short_description || null;
      storyDocument.content.date = genericDocument.data.date || null;
      storyDocument.content.cover_image = genericDocument.data.cover_image || null;
      storyDocument.content.body = genericDocument.data.body || null;
      storyDocument.content.category = genericDocument.data.category || null;
      return storyDocument;
    case "story_category":
      const storyCategoryDocument = genericDocument as StoryCategoryDocument;
      storyCategoryDocument.content.title = genericDocument.data.title;
      storyCategoryDocument.content.description = genericDocument.data.description;
      storyCategoryDocument.content.featured_stories =
        genericDocument.data.featured_stories?.filter(
          // Filter out empty sections
          (item) => !!item.story?.data
        );
      return storyCategoryDocument;
    case "press_kit":
      const pressKitDocument = genericDocument as PressKitDocument;
      pressKitDocument.content.title = genericDocument.data.title;
      pressKitDocument.content.date = genericDocument.data.date || null;
      pressKitDocument.content.link = genericDocument.data.link;
      pressKitDocument.content.cover_image = genericDocument.data.cover_image || null;
      return pressKitDocument;
    case "reseller":
      const resellerDocument = genericDocument as ResellerDocument;
      resellerDocument.content.name = genericDocument.data.name;
      resellerDocument.content.country_code = genericDocument.data.country_code;
      resellerDocument.content.group = genericDocument.data.group;
      resellerDocument.content.website = genericDocument.data.website;
      resellerDocument.content.logo = genericDocument.data.logo;
      resellerDocument.content.active = genericDocument.data.active;
      resellerDocument.content.locations = genericDocument.data.locations?.filter(
        // Filter out empty sections
        (location) => !!location.location_name && location.location_name.length > 0
      );
      return resellerDocument;

    default:
      throw new Error("Unhandled document type");
  }
}

export const prismic = {
  page: {
    get: async (uid: string): Promise<PageDocument> => {
      const document = await getDocument("page", uid);
      return mapProperties(document, "page") as PageDocument;
    },
    query: async () => {
      throw new Error("Not implemented");
    },
  },
  questionCategory: {
    get: {
      single: async (uid: string): Promise<QuestionCategoryDocument> => {
        const document = await getDocument("question_category", uid, [
          "question.title",
        ]);
        return mapProperties(document, "question_category") as QuestionCategoryDocument;
      },
      multiple: async (ids: string[]): Promise<QuestionCategoryDocument[]> => {
        const documents = await getDocuments("question_category", ids, [
          "question.title",
        ]);
        return documents.map(
          (document) =>
            mapProperties(document, "question_category") as QuestionCategoryDocument
        );
      },
    },
  },
  question: {
    get: async (uid: string): Promise<QuestionDocument> => {
      const document = await getDocument("question", uid, [
        "question_category.title",
        "question.title",
        "question.category",
      ]);
      return mapProperties(document, "question") as QuestionDocument;
    },
    query: {
      byCategories: async (
        categories: string[],
        slim = true
      ): Promise<QuestionDocument[]> => {
        // TODO: Pagination
        // { fetch : ['product.title', 'product.price'] }
        // console.log(categories)
        const options: QueryOptions = {
          orderings: "[my.question.title]", // question.priority desc
          pageSize: 100,
        };
        if (slim) {
          options.fetch = ["question.title"];
        }

        const results = await queryDocuments(
          [
            Prismic.Predicates.at("document.type", "question"),
            Prismic.Predicates.any("my.question.category_lookup", categories),
          ],
          options
        );
        return results.map(
          (document) => mapProperties(document, "question") as QuestionDocument
        );
      },
    },
    fullText: async (input: string): Promise<QuestionDocument[]> => {
      const results = await queryDocuments(
        [
          Prismic.Predicates.at("document.type", "question"),
          Prismic.Predicates.fulltext("my.question.body", input),
        ],
        {
          orderings: "[my.question.priority desc, my.question.title]",
          fetch: [
            "question.title",
            "question.category",
            "question.brief",
            "question.body",
          ],
          fetchLinks: ["question.title", "question_category.title"],
        }
      );
      return results.map(
        (document) => mapProperties(document, "question") as QuestionDocument
      );
    },
    similar: async (id: string, max = 30): Promise<QuestionDocument[]> => {
      const results = await queryDocuments(
        [
          Prismic.Predicates.at("document.type", "question"),
          Prismic.Predicates.similar(id, max), // the maximum number of documents that a term may appear in to still be considered relevant
        ],
        {
          fetch: ["question.title", "question.category"],
          fetchLinks: ["question.title", "question_category.title"],
        }
      );
      return results.map(
        (document) => mapProperties(document, "question") as QuestionDocument
      );
    },
  },
  reseller: {
    get: {
      all: async (): Promise<ResellerDocument[]> => {
        const results = await queryDocuments(
          [Prismic.Predicates.at("document.type", "reseller")],
          {
            orderings: "[my.reseller.country_code, my.reseller.name]",
            pageSize: 100,
          },
          true
        );
        return results.map(
          (document) => mapProperties(document, "reseller") as ResellerDocument
        );
      },
      byUID: async (uid: string): Promise<ResellerDocument> => {
        const document = await getDocument("reseller", uid, []);
        return mapProperties(document, "reseller") as ResellerDocument;
      },
    },
  },
  pressKit: {
    get: {
      all: async (): Promise<PressKitDocument[]> => {
        const results = await queryDocuments(
          [Prismic.Predicates.at("document.type", "press_kit")],
          {
            orderings: "[my.press_kit.date desc]",
            pageSize: 100,
          },
          true
        );
        return results.map(
          (document) => mapProperties(document, "press_kit") as PressKitDocument
        );
      },
    },
  },
  storyCategory: {
    get: {
      single: async (uid: string): Promise<StoryCategoryDocument> => {
        const document = await getDocument("story_category", uid, ["story.title"]);
        return mapProperties(document, "story_category") as StoryCategoryDocument;
      },
      multiple: async (ids: string[]): Promise<StoryCategoryDocument[]> => {
        const documents = await getDocuments("story_category", ids, ["story.title"]);
        return documents.map(
          (document) =>
            mapProperties(document, "story_category") as StoryCategoryDocument
        );
      },
    },
  },
  story: {
    get: {
      single: async (uid: string): Promise<StoryDocument> => {
        const document = await getDocument("story", uid, [
          "story_category.title",
          "story.title",
          "story.category",
        ]);
        return mapProperties(document, "story") as StoryDocument;
      },
      all: async (): Promise<StoryDocument[]> => {
        const results = await queryDocuments(
          [Prismic.Predicates.at("document.type", "story")],
          {
            orderings: "[my.story.date desc]",
            pageSize: 100, // TODO: Pagination
          },
          true
        );
        return results.map(
          (document) => mapProperties(document, "story") as StoryDocument
        );
      },
    },
    query: {
      byCategories: async (category: string, slim = true): Promise<StoryDocument[]> => {
        const options: QueryOptions = {
          orderings: "[my.story.date desc]",
          pageSize: 100, // TODO: Pagination
        };
        if (slim) {
          options.fetch = ["story.title", "story.cover_image", "story.category"];
        }

        const results = await queryDocuments(
          [
            Prismic.Predicates.at("document.type", "story"),
            Prismic.Predicates.at("my.story.category", category.toString()),
          ],
          options
        );
        return results.map(
          (document) => mapProperties(document, "story") as StoryDocument
        );
      },
      fetchStoryCategories: async (): Promise<StoryCategoryDocument[]> => {
        const results = await queryDocuments(
          [Prismic.Predicates.at("document.type", "story_category")],
          {
            orderings: "[document.first_publication_date]",
            pageSize: 100,
          }
        );
        return results.map(
          (document) =>
            mapProperties(document, "story_category") as StoryCategoryDocument
        );
      },
    },
  },
};
