import { useUser } from "@auth0/nextjs-auth0/client";
import { useEffect } from "react";
import PrismicPage from "~/components/PrismicPage";
import {
  getAnchors,
  getCategories,
  getEntriesOfType,
  getPage,
  getProducts,
  getSeo,
} from "~/external/prismic";
import { prismic } from "~/external/v2/prismic";
import { useEnhancedEcommerce } from "~/hooks/useEnhancedEcommerce";
import resolveTma2 from "~/lib/ctp/resolveTma2";
import Error from "~/views/Error";
import SimpleProductPage from "~/views/SimpleProductPage";

function Page(props) {
  //get user object from auth0
  // TODO maybe remove - not being used?
  const { user } = useUser();

  // Push to data layer for a product if it exists
  const { viewProduct } = useEnhancedEcommerce();
  const product =
    props.externalData?.products?.find(
      (pr) => pr.key === props.externalData?.page?.uid
    ) ||
    (props.product && typeof props.product === "object" && props.product) ||
    null;

  useEffect(() => {
    if (product) {
      viewProduct(product);
    }
  }, [product, viewProduct]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.href.indexOf("__included_free") !== -1
    ) {
      window.location.href = window.location.href.replace("__included_free", "");
    }
  }, []);
  if (props.error) return <Error {...props.error} />;
  if (!props.page && props.productKey) {
    return <SimpleProductPage {...props} />;
  }
  if (props.page) return <PrismicPage {...props} />;
  return <Error />;
}

Page.getInitialProps = async (ctx) => {
  try {
    const { req, ctpCtx, countryData, query, store } = ctx;
    const { slug = [] } = query;
    const pageUID = slug.length > 0 ? slug[0] : query.pageUID || null; // get pageUID from slug or from query
    const pageSlug = slug.length > 1 ? slug[1] : null;
    const page = await getPage(req, pageUID, true);

    // no page was found, but we have a pageSlug - let's assume it is a productKey
    if (!page && (pageSlug || pageUID)) {
      const productKey = pageSlug || pageUID;
      // add the key to the query
      ctx.query = {
        ...query,
        productKey,
        // key: variantKey
      };

      try {
        const viewProps = await SimpleProductPage.getInitialProps(ctx);
        // retyrnb the varientKey in the props, so render knows to maike a SimpleProductPage
        return { ...viewProps, productKey };
      } catch (err) {
        // apparently it was not a product.. oops!
        // page = undefined; // handle it like a 404
        return {
          error: {
            statusCode: 404,
            message: "Page not found",
          },
        };
      }
    }

    // Not found
    if (page === undefined) {
      return {
        error: {
          statusCode: 404,
          message: "Page not found",
        },
      };
    }

    // get all products on the page
    // - Note: we will also add all the products from the variants
    let productEntries = getEntriesOfType(page, "product");
    // get tma-2 variants
    const variantEntries = getEntriesOfType(page, "variant");

    const tma2s = [];
    // if there is any...
    if (variantEntries.length > 0) {
      variantEntries.forEach((tma2) => {
        const parts = [];

        tma2.data.parts.forEach((pr) => {
          // SHOULD BE RESOLVE A conf here?
          if (pr.part.hasOwnProperty("uid")) {
            productEntries.push(pr.part);
            parts.push(pr.part);
          }
        });

        // no parts selected.. it's invalid...
        if (parts.length === 0) return;

        // sort parts by uid
        parts.sort((a, b) => {
          if (a.uid < b.uid) return 1;
          if (a.uid > b.uid) return -1;
          return 0;
        });

        tma2s.push({ ...tma2, parts });
      });
    }

    // remove dupes?
    productEntries = productEntries.reduce((entries, pr) => {
      const alreadyInArray = entries.find((e) => e.uid === pr.uid);
      if (!alreadyInArray) entries.push(pr);
      return entries;
    }, []);

    // fethh the actual products
    const products = await getProducts(productEntries, ctpCtx, countryData);

    // turn all the TMA-2s into "configurations"
    const configurations = await Promise.all(
      tma2s.map(async (tma2) => {
        // take each part and add the resolved product to a producst array!
        const configurationProducts = tma2.parts.map((part) =>
          products.find((pr) => pr.key === part.uid)
        );

        const serialPretty = tma2.parts
          .map((part) => part.data.key)
          .join("-")
          .toUpperCase();
        const serial = serialPretty.toLowerCase().replace(/-/g, "");
        // const uidSerial = serialPretty.toLowerCase();

        const key = `tma-2-${serial}`;
        const name = `TMA-2 ${serialPretty}`;

        let configuration = {
          key,
          name,
          productType: { key: "tma-2-configuration" },
        };

        configuration = await resolveTma2(
          ctpCtx,
          configuration,
          countryData,
          configurationProducts
        );

        // modify the configuration to match our tma-2 variant!
        configuration = {
          uid: `tma-2-${serialPretty.toLowerCase()}`, // used to match in slice
          data: tma2.data,
          serial, // used to resolve link to conf...
          ...configuration,
        };

        return configuration;
      })
    );

    // fetch categories
    const categoryEntries = getEntriesOfType(page, "category");
    const categories = await getCategories(categoryEntries, ctpCtx, countryData);

    // fetch anchors
    const anchors = getAnchors(page);

    // fetch seo
    const seo = getSeo(page);

    // fetch questions - TODO: Move
    const questionCategoryGrids = page.data.body.filter(
      (slice) => slice.slice_type == "question_category_grid"
    );
    if (questionCategoryGrids.length > 0) {
      for (const questionCategoryGrid of questionCategoryGrids) {
        const results = await prismic.questionCategory.get.multiple(
          questionCategoryGrid.items
            .filter((item) => !!item.question_category.id)
            .map((item) => item.question_category.id)
        );
        questionCategoryGrid.merged = {};
        for (const item of questionCategoryGrid.items) {
          questionCategoryGrid.merged[item.question_category.uid] = results.find(
            (result) => result.uid == item.question_category.uid
          );
        }
      }
    }

    // fetch articles - TODO: Move
    const storyCategoryGrids = page.data.body.filter(
      (slice) => slice.slice_type == "story_category_grid"
    );
    if (storyCategoryGrids.length > 0) {
      for (const storyCategoryGrid of storyCategoryGrids) {
        const results = await prismic.storyCategory.get.multiple(
          storyCategoryGrid.items
            .filter((item) => !!item.story_category.id)
            .map((item) => item.story_category.id)
        );
        storyCategoryGrid.merged = {};
        for (const item of storyCategoryGrid.items) {
          storyCategoryGrid.merged[item.story_category.uid] = results.find(
            (result) => result.uid == item.story_category.uid
          );
        }
      }
    }

    const storiesOverview = page.data.body.find(
      (slice) => slice.slice_type == "stories_overview"
    );
    if (storiesOverview) {
      const stories = await prismic.story.get.all();
      storiesOverview.merged = { stories };
    }

    const storiesNavigation = page.data.body.find(
      (slice) => slice.slice_type == "stories_navigation"
    );
    if (storiesNavigation) {
      const categories = await prismic.story.query.fetchStoryCategories();
      const stories = await prismic.story.get.all(); // TODO: Reduce API calls

      // Assign the stories to their respective categories
      const mergedCategories = categories.map((category) => {
        const categoryStories = stories.filter(
          (story) =>
            story.content.category && story.content.category.uid === category.uid
        );
        return { ...category, stories: categoryStories };
      });

      storiesNavigation.merged = { categories: mergedCategories };
    }

    const resellersOverview = page.data.body.find(
      (slice) => slice.slice_type == "resellers_overview"
    );
    if (resellersOverview) {
      const resellers = (await prismic.reseller.get.all()).filter(
        (r) => r.data.visible_only_for_product_registration !== true
      );
      resellersOverview.merged = { resellers };
    }

    const fakeRouter = {
      query: ctx.query,
      route: ctx.pathname,
      pathname: ctx.pathname,
      asPath:
        typeof window !== "undefined"
          ? window.location.pathname
          : ctx.req?.originalUrl ?? "/",
    };

    const externalData = {
      page, // <-- access page in slices!
      state: store.getState(), // <-- access current State in slices!
      router: fakeRouter,
      products,
      anchors,
      seo,
      categories,
      configurations,
      pageSlug,
    };

    return { page, externalData };
  } catch (err) {
    console.error("PrismicPage error", err);
    return {
      error: true,
    };
  }
};

export default Page;
